<template>
  <el-dialog
    title="标签详情"
    v-dialogDrag
    :visible.sync="show"
    :before-close="handleClose"
  >
    <div class="detail-container">
      <div>
        <p>
          <span>公众号：</span>
          <span>{{ detailInfo.channelName }}</span>
        </p>
        <p>
          <span>标签名：</span>
          <span>{{ detailInfo.tagName }}</span>
        </p>
        <p>
          <span>备注：</span>
          <span>{{ detailInfo.remark }}</span>
        </p>
        <p>
          <span>发送次数：</span>
          <span>{{
            `${getValue(detailInfo.minSendNum)} -  ${getValue(
              detailInfo.maxSendNum
            )}`
          }}</span>
        </p>
        <p>
          <span>互动类型：</span>
          <span>
            <el-tag :type="getTagType(detailInfo.tagType).type">
              {{ getTagType(detailInfo.tagType).text }}
            </el-tag>
          </span>
        </p>
        <p>
          <span>接收用户：</span>
          <span>{{
            `${detailInfo.sendAll === 1 ? "全部用户" : "标签用户"}`
          }}</span>
        </p>
      </div>
      <div class="recharge-detail" v-if="detailInfo.sendAll === 0">
        <p>
          <span>充值情况：</span>
          <span>{{ getRechargeType(detailInfo.rechargeType) }}</span>
        </p>
        <template v-if="detailInfo.rechargeType === 2">
          <p
            v-if="
              !isFalsey(detailInfo.rechargeTimeSpaceStart) ||
              !isFalsey(detailInfo.rechargeTimeSpaceEnd)
            "
          >
            <span>充值间隔：</span>
            <span>
              {{
                `${getValue(detailInfo.rechargeTimeSpaceStart)} - ${getValue(
                  detailInfo.rechargeTimeSpaceEnd
                )}`
              }}
            </span>
          </p>
          <p
            v-if="
              !isFalsey(detailInfo.minTotalRecharge) ||
              !isFalsey(detailInfo.maxTotalRecharge)
            "
          >
            <span>累计充值：</span>
            <span>
              {{
                `${getValue(detailInfo.minTotalRecharge)} - ${getValue(
                  detailInfo.maxTotalRecharge
                )}`
              }}
            </span>
          </p>
          <p
            v-if="
              !isFalsey(detailInfo.minLastRechargeAmt) ||
              !isFalsey(detailInfo.maxLastRechargeAmt)
            "
          >
            <span>最后充值：</span>
            <span>
              {{
                `${getValue(detailInfo.minLastRechargeAmt)} - ${getValue(
                  detailInfo.maxLastRechargeAmt
                )}`
              }}
            </span>
          </p>
          <p
            v-if="
              !isFalsey(detailInfo.minRechargeCount) ||
              !isFalsey(detailInfo.maxRechargeCount)
            "
          >
            <span>充值次数：</span>
            <span>
              {{
                `${getValue(detailInfo.minRechargeCount)} - ${getValue(
                  detailInfo.maxRechargeCount
                )}`
              }}
            </span>
          </p>
          <p
            v-if="
              !isFalsey(detailInfo.minSevenRecharge) ||
              !isFalsey(detailInfo.maxSevenRecharge)
            "
          >
            <span>近七日充值：</span>
            <span>{{
              `${getValue(detailInfo.minSevenRecharge)} - ${getValue(
                detailInfo.maxSevenRecharge
              )}`
            }}</span>
          </p>
          <p
            v-if="
              !isFalsey(detailInfo.minKanDianRate) ||
              !isFalsey(detailInfo.maxKanDianRate)
            "
          >
            <span>书币消耗率：</span>
            <span>{{
              `${getValue(detailInfo.minKanDianRate)} - ${getValue(
                detailInfo.maxKanDianRate
              )}`
            }}</span>
          </p>
          <p
            v-if="
              !isFalsey(detailInfo.minKanDian) ||
              !isFalsey(detailInfo.maxKanDian)
            "
          >
            <span>剩余书币：</span>
            <span>
              {{
                `${getValue(detailInfo.minKanDian)} - ${getValue(
                  detailInfo.maxKanDian
                )}`
              }}
            </span>
          </p>
          <p>
            <span>是否是vip：</span>
            <span>
              {{ getVipName(detailInfo.isVip) }}
            </span>
          </p>
          <p>
            <span>连续充值：</span>
            <span>{{
              `${
                isFalsey(detailInfo.continuousRecharge)
                  ? "暂无"
                  : detailInfo.continuousRecharge === 0
                  ? "不限"
                  : "是"
              }`
            }}</span>
          </p>
          <p>
            <span>优惠敏感度：</span>
            <span>{{ `${getCouponLevelName(detailInfo.couponLevel)}` }}</span>
          </p>
          <p>
            <span>RFM：</span>
            <el-tag v-if="detailInfo.rfm.includes('0')" type="info"
              >不限</el-tag
            >
            <el-tag
              v-if="detailInfo.rfm.includes('1')"
              class="margin-right-ten"
              type="success"
              >A类</el-tag
            >
            <el-tag
              v-if="detailInfo.rfm.includes('2')"
              class="margin-right-ten"
              type="warning"
              >B类</el-tag
            >
            <el-tag
              v-if="detailInfo.rfm.includes('3')"
              class="margin-right-ten"
              type="danger"
              >C类</el-tag
            >
          </p>
          <p>
            <span>书籍：</span>
            <span>{{
              detailInfo.bookName ? detailInfo.bookName : "暂无"
            }}</span>
          </p>
        </template>

        <p>
          <span>性别：</span>
          <span>{{ getSexType(detailInfo.sex) }}</span>
        </p>
        <p>
          <span>所在地区：</span>
          <span>{{
            `${isFalsey(detailInfo.province) ? "" : detailInfo.province} - ${
              isFalsey(detailInfo.city) ? "" : detailInfo.city
            }`
          }}</span>
        </p>
        <p
          v-if="
            !isFalsey(detailInfo.minSignInCount) ||
            !isFalsey(detailInfo.maxSignInCount)
          "
        >
          <span>签到次数：</span>
          <span>
            {{
              `${getValue(detailInfo.minSignInCount)} - ${getValue(
                detailInfo.maxSignlnCount
              )}`
            }}
          </span>
        </p>
        <p
          v-if="
            !isFalsey(detailInfo.startDate) || !isFalsey(detailInfo.endDate)
          "
        >
          <span>关注时间：</span>
          <span>{{
            `${getValue(detailInfo.startDate)} - ${getValue(
              detailInfo.endDate
            )}`
          }}</span>
        </p>
      </div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    detailInfo: {
      type: Object,
      default: () => {},
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      obj: {},
    };
  },
  methods: {
    handleClose() {
      this.$emit("close");
      // this.$emit('update:show',false)
    },
    renderData() {
      // Object.entries(this.detailInfo).forEach(([key,value])=>{
      // })
    },
    getValue(val) {
      return val === undefined || val === null ? "暂无" : val;
    },
    isFalsey(val) {
      return val === undefined || val === null;
    },
    getTagType(val) {
      if (val === undefined || val === null)
        return {
          type: "info",
          text: "暂无",
        };
      if (val === 0)
        return {
          type: "",
          text: "全部",
        };
      if (val === 1)
        return {
          type: "success",
          text: "活跃",
        };
      if (val === 2)
        return {
          type: "danger",
          text: "不活跃",
        };
    },
    getRechargeType(val) {
      if (val === undefined || val === null) return "暂无";
      if (val === 0) return "不限";
      if (val === 1) return "未充值";
      if (val === 2) return "已充值";
    },
    getVipName(val) {
      if (this.isFalsey(val)) return "暂无";
      if (val === 0) return "否";
      if (val === 1) return "是";
      if (val === 2) return "不限";
    },
    getCouponLevelName(val) {
      if (this.isFalsey(val)) return "暂无";
      if (val === 0) return "不限";
      if (val === 1) return "一级";
      if (val === 2) return "二级";
      if (val === 3) return "三级";
      if (val === 4) return "四级";
      if (val === 5) return "五级";
    },
    getRFMName(val) {
      if (this.isFalsey(val)) return "暂无";
      if (val === 0) return "不限";
      if (val === 1) return "A类";
      if (val === 2) return "B类";
      if (val === 3) return "C类";
    },
    getSexType(val) {
      if (this.isFalsey(val)) return "暂无";
      if (val === 0) return "不限";
      if (val === 1) return "男";
      if (val === 2) return "女";
    },
  },
  components: {},
};
</script>
<style lang='scss' scoped>
/deep/ .el-dialog {
  // min-width:400px;
  max-width: 768px;
}

.detail-container {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  > div:nth-of-type(1) {
    width: 40%;
  }
  p {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 20px;
    > span:nth-of-type(1) {
      // width:40%
      width: 120px;
      text-align: right;
    }
    > span:nth-last-of-type(1) {
      flex-grow: 1;
      margin-left: 20px;
    }
  }
}
</style>