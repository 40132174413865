export default
  [
    {
      children: [
        {
          cid: 542100,
          name: '昌都',
        },
        {
          cid: 540100,
          name: '拉萨',
        },
        {
          cid: 542600,
          name: '林芝',
        },
        {
          cid: 540200,
          name: '日喀则',
        },
        {
          cid: 542200,
          name: '山南',
        },
        {
          cid: 542500,
          name: '阿里地',
        },
        {
          cid: 542400,
          name: '那曲',
        }
      ],
      cid: 540000,
      name: '西藏',
    },
    {
      children: [
        {
          cid: 630200,
          name: '海东',
        },
        {
          cid: 630100,
          name: '西宁',
        },
      ],
      cid: 630000,
      name: '青海',
    },
    {
      children: [
        {
          cid: 510300,
          name: '自贡',
        },
        {
          cid: 511500,
          name: '宜宾',
        },
        {
          cid: 512000,
          name: '资阳',
        },
        {
          cid: 510500,
          name: '泸州',
        },
        {
          cid: 511400,
          name: '眉山',
        },
        {
          cid: 510700,
          name: '绵阳',
        },
        {
          cid: 511000,
          name: '内江',
        },
        {
          cid: 511300,
          name: '南充',
        },
        {
          cid: 510400,
          name: '攀枝花',
        },
        {
          cid: 510900,
          name: '遂宁',
        },
        {
          cid: 511800,
          name: '雅安',
        },
        {
          cid: 511900,
          name: '巴中',
        },
        {
          cid: 510100,
          name: '成都',
        },
        {
          cid: 511700,
          name: '达州',
        },
        {
          cid: 510600,
          name: '德阳',
        },
        {
          cid: 511600,
          name: '广安',
        },
        {
          cid: 510800,
          name: '广元',
        },
        {
          cid: 511100,
          name: '乐山',
        }
      ],
      cid: 510000,
      name: '四川',
    },
    {
      children: [
        {
          cid: 451000,
          name: '百色',
        },
        {
          cid: 450500,
          name: '北海',
        },
        {
          cid: 451400,
          name: '崇左',
        },
        {
          cid: 450600,
          name: '防城港',
        },
        {
          cid: 450800,
          name: '贵港',
        },
        {
          cid: 450300,
          name: '桂林',
        },
        {
          cid: 451200,
          name: '河池',
        },
        {
          cid: 451100,
          name: '贺州',
        },
        {
          cid: 451300,
          name: '来宾',
        },
        {
          cid: 450200,
          name: '柳州',
        },
        {
          cid: 450100,
          name: '南宁',
        },
        {
          cid: 450700,
          name: '钦州',
        },
        {
          cid: 450400,
          name: '梧州',
        },
        {
          cid: 450900,
          name: '玉林',
        }
      ],
      cid: 450000,
      name: '广西',
    },
    {
      children: [
        {
          cid: 321000,
          name: '扬州',
        },
        {
          cid: 321100,
          name: '镇江',
        },
        {
          cid: 320400,
          name: '常州',
        },
        {
          cid: 320800,
          name: '淮安',
        },
        {
          cid: 320700,
          name: '连云港',
        },
        {
          cid: 320100,
          name: '南京',
        },
        {
          cid: 320600,
          name: '南通',
        },
        {
          cid: 320500,
          name: '苏州',
        },
        {
          cid: 321300,
          name: '宿迁',
        },
        {
          cid: 321200,
          name: '泰州',
        },
        {
          cid: 320200,
          name: '无锡',
        },
        {
          cid: 320300,
          name: '徐州',
        },
        {
          cid: 320900,
          name: '盐城',
        }
      ],
      cid: 320000,
      name: '江苏',
    },
    {
      children: [
        {
          cid: 220400,
          name: '辽源',
        },
        {
          cid: 220300,
          name: '四平',
        },
        {
          cid: 220700,
          name: '松原',
        },
        {
          cid: 220500,
          name: '通化',
        },
        {
          cid: 220800,
          name: '白城',
        },
        {
          cid: 220600,
          name: '白山',
        },
        {
          cid: 220100,
          name: '长春',
        },
        {
          cid: 220200,
          name: '吉林',
        }
      ],
      cid: 220000,
      name: '吉林',
    },
    {
      children: [
        {
          cid: 371600,
          name: '滨州',
        },
        {
          cid: 371400,
          name: '德州',
        },
        {
          cid: 370500,
          name: '东营',
        },
        {
          cid: 371700,
          name: '菏泽',
        },
        {
          cid: 370100,
          name: '济南',
        },
        {
          cid: 370800,
          name: '济宁',
        },
        {
          cid: 371200,
          name: '莱芜区',
        },
        {
          cid: 371500,
          name: '聊城',
        },
        {
          cid: 371300,
          name: '临沂',
        },
        {
          cid: 370200,
          name: '青岛',
        },
        {
          cid: 371100,
          name: '日照',
        },
        {
          cid: 370900,
          name: '泰安',
        },
        {
          cid: 371000,
          name: '威海',
        },
        {
          cid: 370700,
          name: '潍坊',
        },
        {
          cid: 370600,
          name: '烟台',
        },
        {
          cid: 370400,
          name: '枣庄',
        },
        {
          cid: 370300,
          name: '淄博',
        }
      ],
      cid: 370000,
      name: '山东',
    },
    {
      children: [
        {
          cid: 340800,
          name: '安庆',
        },
        {
          cid: 340300,
          name: '蚌埠',
        },
        {
          cid: 341600,
          name: '亳州',
        },
        {
          cid: 341700,
          name: '池州',
        },
        {
          cid: 341100,
          name: '滁州',
        },
        {
          cid: 341200,
          name: '阜阳',
        },
        {
          cid: 340100,
          name: '合肥',
        },
        {
          cid: 340600,
          name: '淮北',
        },
        {
          cid: 340400,
          name: '淮南',
        },
        {
          cid: 341000,
          name: '黄山',
        },
        {
          cid: 341500,
          name: '六安',
        },
        {
          cid: 340500,
          name: '马鞍山',
        },
        {
          cid: 341300,
          name: '宿州',
        },
        {
          cid: 340700,
          name: '铜陵',
        },
        {
          cid: 340200,
          name: '芜湖',
        },
        {
          cid: 341800,
          name: '宣城',
        }
      ],
      cid: 340000,
      name: '安徽',
    },
    {
      children: [
        {
          cid: 150100,
          name: '呼和浩特',
        },
        {
          cid: 150700,
          name: '呼伦贝尔',
        },
        {
          cid: 150500,
          name: '通辽',
        },
        {
          cid: 150300,
          name: '乌海',
        },
        {
          cid: 150900,
          name: '乌兰察布',
        },
        {
          cid: 152500,
          name: '锡林郭勒盟',
        },
        {
          cid: 152200,
          name: '兴安盟',
        },
        {
          cid: 152900,
          name: '阿拉善盟',
        },
        {
          cid: 150800,
          name: '巴彦淖尔',
        },
        {
          cid: 150200,
          name: '包头',
        },
        {
          cid: 150400,
          name: '赤峰',
        },
        {
          cid: 150600,
          name: '鄂尔多斯',
        }
      ],
      cid: 150000,
      name: '内蒙古',
    },
    {
      children: [
        {
          cid: 140400,
          name: '长治',
        },
        {
          cid: 140200,
          name: '大同',
        },
        {
          cid: 140500,
          name: '晋城',
        },
        {
          cid: 140700,
          name: '晋中',
        },
        {
          cid: 141000,
          name: '临汾',
        },
        {
          cid: 141100,
          name: '吕梁',
        },
        {
          cid: 140600,
          name: '朔州',
        },
        {
          cid: 140100,
          name: '太原',
        },
        {
          cid: 140900,
          name: '忻州',
        },
        {
          cid: 140300,
          name: '阳泉',
        },
        {
          cid: 140800,
          name: '运城',
        }
      ],
      cid: 140000,
      name: '山西',
    },
    {
      children: [
        {
          cid: 420700,
          name: '鄂州',
        },
        {
          cid: 421100,
          name: '黄冈',
        },
        {
          cid: 420200,
          name: '黄石',
        },
        {
          cid: 420800,
          name: '荆门',
        },
        {
          cid: 421000,
          name: '荆州',
        },
        {
          cid: 429005,
          name: '潜江',
        },
        {
          cid: 429021,
          name: '神农架林',
        },
        {
          cid: 420300,
          name: '十堰',
        },
        {
          cid: 421300,
          name: '随州',
        },
        {
          cid: 429006,
          name: '天门',
        },
        {
          cid: 420100,
          name: '武汉',
        },
        {
          cid: 429004,
          name: '仙桃',
        },
        {
          cid: 421200,
          name: '咸宁',
        },
        {
          cid: 420600,
          name: '襄阳',
        },
        {
          cid: 420900,
          name: '孝感',
        },
        {
          cid: 420500,
          name: '宜昌',
        }
      ],
      cid: 420000,
      name: '湖北',
    },
    {
      children: [
        {
          cid: 130600,
          name: '保定',
        },
        {
          cid: 130900,
          name: '沧州',
        },
        {
          cid: 130800,
          name: '承德',
        },
        {
          cid: 130400,
          name: '邯郸',
        },
        {
          cid: 131100,
          name: '衡水',
        },
        {
          cid: 131000,
          name: '廊坊',
        },
        {
          cid: 130300,
          name: '秦皇岛',
        },
        {
          cid: 130100,
          name: '石家庄',
        },
        {
          cid: 130200,
          name: '唐山',
        },
        {
          cid: 130500,
          name: '邢台',
        },
        {
          cid: 130700,
          name: '张家口',
        }
      ],
      cid: 130000,
      name: '河北',
    },
    {
      children: [
        {
          cid: 361000,
          name: '抚州',
        },
        {
          cid: 360700,
          name: '赣州',
        },
        {
          cid: 360800,
          name: '吉安',
        },
        {
          cid: 360200,
          name: '景德镇',
        },
        {
          cid: 360400,
          name: '九江',
        },
        {
          cid: 360100,
          name: '南昌',
        },
        {
          cid: 360300,
          name: '萍乡',
        },
        {
          cid: 361100,
          name: '上饶',
        },
        {
          cid: 360500,
          name: '新余',
        },
        {
          cid: 360900,
          name: '宜春',
        },
        {
          cid: 360600,
          name: '鹰潭',
        }
      ],
      cid: 360000,
      name: '江西',
    },
    {
      children: [
        {
          cid: 310101,
          name: '黄浦',
        },
        {
          cid: 310104,
          name: '徐汇',
        },
        {
          cid: 310105,
          name: '长宁',
        },
        {
          cid: 310106,
          name: '静安',
        },
        {
          cid: 310107,
          name: '普陀',
        },
        {
          cid: 310109,
          name: '虹口',
        },
        {
          cid: 310110,
          name: '杨浦',
        },
        {
          cid: 310112,
          name: '闵行',
        },
        {
          cid: 310113,
          name: '宝山',
        },
        {
          cid: 310114,
          name: '嘉定',
        },
        {
          cid: 310115,
          name: '浦东新区',
        },
        {
          cid: 310116,
          name: '金山',
        },
        {
          cid: 310117,
          name: '松江',
        },
        {
          cid: 310118,
          name: '青浦',
        },
        {
          cid: 310120,
          name: '奉贤',
        },
        {
          cid: 310230,
          name: '崇明',
        }
      ],
      cid: 310000,
      name: '上海',
    },
    {
      children: [
        {
          cid: 330100,
          name: '杭州',
        },
        {
          cid: 330500,
          name: '湖州',
        },
        {
          cid: 330400,
          name: '嘉兴',
        },
        {
          cid: 330700,
          name: '金华',
        },
        {
          cid: 331100,
          name: '丽水',
        },
        {
          cid: 330200,
          name: '宁波',
        },
        {
          cid: 330800,
          name: '衢州',
        },
        {
          cid: 330600,
          name: '绍兴',
        },
        {
          cid: 331000,
          name: '台州',
        },
        {
          cid: 330300,
          name: '温州',
        },
        {
          cid: 330900,
          name: '舟山',
        }
      ],
      cid: 330000,
      name: '浙江',
    },
    {
      children: [
        {
          name: "花地玛堂区",
          cid: 0
        },
        {
          name: "圣安多尼堂区",
          cid: 1
        },
        {
          name: "大堂区",
          cid: 2
        },
        {
          name: "望德堂区",
          cid: 3
        },
        {
          name: "风顺堂区",
          cid: 4
        },
        {
          name: "路凼",
          cid: 7
        }
      ],
      cid: 820000,
      name: '澳门',
    },
    {
      children: [
        {
          cid: 659002,
          name: '阿拉尔',
        },
        {
          cid: 654300,
          name: '阿勒泰地',
        },
        {
          cid: 652200,
          name: '哈密',
        },
        {
          cid: 653200,
          name: '和田地',
        },
        {
          cid: 653100,
          name: '喀什地',
        },
        {
          cid: 650200,
          name: '克拉玛依',
        },
        {
          cid: 659001,
          name: '石河子',
        },
        {
          cid: 654200,
          name: '塔城地',
        },
        {
          cid: 652100,
          name: '吐鲁番',
        },
        {
          cid: 650100,
          name: '乌鲁木齐',
        },
        {
          cid: 652900,
          name: '阿克苏地',
        },
        {
          cid: 659004,
          name: '五家渠',
        },
        {
          cid: 659003,
          name: '图木舒克',
        }
      ],
      cid: 650000,
      name: '新疆',
    },
    {
      children: [
        {
          cid: 350100,
          name: '福州',
        },
        {
          cid: 350800,
          name: '龙岩',
        },
        {
          cid: 350700,
          name: '南平',
        },
        {
          cid: 350900,
          name: '宁德',
        },
        {
          cid: 350300,
          name: '莆田',
        },
        {
          cid: 350500,
          name: '泉州',
        },
        {
          cid: 350400,
          name: '三明',
        },
        {
          cid: 350200,
          name: '厦门',
        },
        {
          cid: 350600,
          name: '漳州',
        }
      ],
      cid: 350000,
      name: '福建',
    },
    {
      children: [
        {
          cid: 120101,
          name: '和平',
        },
        {
          cid: 120102,
          name: '河东',
        },
        {
          cid: 120103,
          name: '河西',
        },
        {
          cid: 120104,
          name: '南开',
        },
        {
          cid: 120105,
          name: '河北',
        },
        {
          cid: 120106,
          name: '红桥',
        },
        {
          cid: 120110,
          name: '东丽',
        },
        {
          cid: 120111,
          name: '西青',
        },
        {
          cid: 120112,
          name: '津南',
        },
        {
          cid: 120113,
          name: '北辰',
        },
        {
          cid: 120114,
          name: '武清',
        },
        {
          cid: 120115,
          name: '宝坻',
        },
        {
          cid: 120116,
          name: '滨海新区',
        },
        {
          cid: 120221,
          name: '宁河',
        },
        {
          cid: 120223,
          name: '静海',
        },
        {
          cid: 120225,
          name: '蓟州',
        }
      ],
      cid: 120000,
      name: '天津',
    },
    {
      children: [
        {
          cid: 110101,
          name: '东城',
        },
        {
          cid: 110102,
          name: '西城',
        },
        {
          cid: 110105,
          name: '朝阳',
        },
        {
          cid: 110106,
          name: '丰台',
        },
        {
          cid: 110107,
          name: '石景山',
        },
        {
          cid: 110108,
          name: '海淀',
        },
        {
          cid: 110109,
          name: '门头沟',
        },
        {
          cid: 110111,
          name: '房山',
        },
        {
          cid: 110112,
          name: '通州',
        },
        {
          cid: 110113,
          name: '顺义',
        },
        {
          cid: 110114,
          name: '昌平',
        },
        {
          cid: 110115,
          name: '大兴',
        },
        {
          cid: 110116,
          name: '怀柔',
        },
        {
          cid: 110117,
          name: '平谷',
        },
        {
          cid: 110228,
          name: '密云',
        },
        {
          cid: 110229,
          name: '延庆',
        }
      ],
      cid: 110000,
      name: '北京',
    },
    {
      children: [
        {
          cid: 640400,
          name: '固原',
        },
        {
          cid: 640100,
          name: '银川',
        },
        {
          cid: 640200,
          name: '石嘴山',
        },
        {
          cid: 640300,
          name: '吴忠',
        },
        {
          cid: 640500,
          name: '中卫',
        }
      ],
      cid: 640000,
      name: '宁夏',
    },
    {
      children: [
        {
          cid: 530500,
          name: '保山',
        },
        {
          cid: 530400,
          name: '玉溪',
        },
        {
          cid: 530600,
          name: '昭通',
        },
        {
          cid: 530300,
          name: '曲靖',
        },
        {
          cid: 530800,
          name: '普洱',
        },
        {
          cid: 530100,
          name: '昆明',
        },
        {
          cid: 530700,
          name: '丽江',
        },
        {
          cid: 530900,
          name: '临沧',
        }
      ],
      cid: 530000,
      name: '云南',
    },
    {
      children: [
        {
          cid: 210300,
          name: '鞍山',
        },
        {
          cid: 210500,
          name: '本溪',
        },
        {
          cid: 211300,
          name: '朝阳',
        },
        {
          cid: 210800,
          name: '营口',
        },
        {
          cid: 210100,
          name: '沈阳',
        },
        {
          cid: 211200,
          name: '铁岭',
        },
        {
          cid: 210200,
          name: '大连',
        },
        {
          cid: 210600,
          name: '丹东',
        },
        {
          cid: 210400,
          name: '抚顺',
        },
        {
          cid: 210900,
          name: '阜新',
        },
        {
          cid: 211400,
          name: '葫芦岛',
        },
        {
          cid: 210700,
          name: '锦州',
        },
        {
          cid: 211000,
          name: '辽阳',
        },
        {
          cid: 211100,
          name: '盘锦',
        }
      ],
      cid: 210000,
      name: '辽宁',
    },
    {
      children: [
        {
          cid: 610900,
          name: '安康',
        },
        {
          cid: 610300,
          name: '宝鸡',
        },
        {
          cid: 610700,
          name: '汉中',
        },
        {
          cid: 611000,
          name: '商洛',
        },
        {
          cid: 610600,
          name: '延安',
        },
        {
          cid: 610800,
          name: '榆林',
        },
        {
          cid: 610200,
          name: '铜川',
        },
        {
          cid: 610500,
          name: '渭南',
        },
        {
          cid: 610100,
          name: '西安',
        },
        {
          cid: 610400,
          name: '咸阳',
        }
      ],
      cid: 610000,
      name: '陕西',
    },
    {
      children: [
        {
          cid: 520400,
          name: '安顺',
        },
        {
          cid: 520500,
          name: '毕节',
        },
        {
          cid: 520100,
          name: '贵阳',
        },
        {
          cid: 520200,
          name: '六盘水',
        },
        {
          cid: 520600,
          name: '铜仁',
        },
        {
          cid: 520300,
          name: '遵义',
        }
      ],
      cid: 520000,
      name: '贵州',
    },
    {
      children: [
        {
          name: "深水埗区",
          cid: 991456
        },
        {
          name: "油尖旺区",
          cid: 454001
        },
        {
          name: "九龙城区",
          cid: 454002
        },
        {
          name: "黄大仙区",
          cid: 454003
        },
        {
          name: "观塘区",
          cid: 454004
        },
        {
          name: "北区",
          cid: 454005
        },
        {
          name: "大埔区",
          cid: 454006
        },
        {
          name: "沙田区",
          cid: 454007
        },
        {
          name: "西贡区",
          cid: 599001
        },
        {
          name: "元朗区",
          cid: 99002
        },
        {
          name: "屯门区",
          cid: 1450010
        },
        {
          name: "荃湾区",
          cid: 1450011
        },
        {
          name: "葵青区",
          cid: 1450012
        },
        {
          name: "离岛区",
          cid: 1450013
        },
        {
          name: "中西区",
          cid: 1450014
        },
        {
          name: "湾仔区",
          cid: 1450015
        },
        {
          name: "东区",
          cid: 1450016
        },
        {
          name: "南区",
          cid: 1450017
        }
      ],
      cid: 810000,
      name: '香港',
    },
    {
      children: [
        {
          cid: 230700,
          name: '伊春',
        },
        {
          cid: 230900,
          name: '七台河',
        },
        {
          cid: 230200,
          name: '齐齐哈尔',
        },
        {
          cid: 230500,
          name: '双鸭山',
        },
        {
          cid: 231200,
          name: '绥化',
        },
        {
          cid: 230600,
          name: '大庆',
        },
        {
          cid: 232700,
          name: '大兴安岭地',
        },
        {
          cid: 230100,
          name: '哈尔滨',
        },
        {
          cid: 230400,
          name: '鹤岗',
        },
        {
          cid: 231100,
          name: '黑河',
        },
        {
          cid: 230300,
          name: '鸡西',
        },
        {
          cid: 230800,
          name: '佳木斯',
        },
        {
          cid: 231000,
          name: '牡丹江',
        }
      ],
      cid: 230000,
      name: '黑龙江',
    },
    {
      children: [
        {
          cid: 469023,
          name: '澄迈',
        },
        {
          cid: 469003,
          name: '儋州',
        },
        {
          cid: 469021,
          name: '定安',
        },
        {
          cid: 469007,
          name: '东方',
        },
        {
          cid: 460100,
          name: '海口',
        },
        {
          cid: 469024,
          name: '临高',
        },
        {
          cid: 469002,
          name: '琼海',
        },
        {
          cid: 460200,
          name: '三亚',
        },
        {
          cid: 469001,
          name: '五指山',
        },
        {
          cid: 469006,
          name: '万宁',
        },
        {
          cid: 469005,
          name: '文昌',
        },
        {
          cid: 469022,
          name: '屯昌',
        },
        {
          cid: 460300,
          name: '三沙',
        }
      ],
      cid: 460000,
      name: '海南',
    },
    {
      children: [
        {
          cid: 440400,
          name: '珠海',
        },
        {
          cid: 445300,
          name: '云浮',
        },
        {
          cid: 440800,
          name: '湛江',
        },
        {
          cid: 441200,
          name: '肇庆',
        },
        {
          cid: 442000,
          name: '中山',
        },
        {
          cid: 440900,
          name: '茂名',
        },
        {
          cid: 441400,
          name: '梅州',
        },
        {
          cid: 441800,
          name: '清远',
        },
        {
          cid: 440500,
          name: '汕头',
        },
        {
          cid: 441500,
          name: '汕尾',
        },
        {
          cid: 440200,
          name: '韶关',
        },
        {
          cid: 440300,
          name: '深圳',
        },
        {
          cid: 441700,
          name: '阳江',
        },
        {
          cid: 445100,
          name: '潮州',
        },
        {
          cid: 441900,
          name: '东莞',
        },
        {
          cid: 440600,
          name: '佛山',
        },
        {
          cid: 440100,
          name: '广州',
        },
        {
          cid: 441600,
          name: '河源',
        },
        {
          cid: 441300,
          name: '惠州',
        },
        {
          cid: 440700,
          name: '江门',
        },
        {
          cid: 445200,
          name: '揭阳',
        }
      ],
      cid: 440000,
      name: '广东',
    },
    {
      children: [
        {
          cid: 500101,
          name: '万州',
        },
        {
          cid: 5001000,
          name: '两江新区',
        },
        {
          cid: 500102,
          name: '涪陵',
        },
        {
          cid: 500103,
          name: '渝中',
        },
        {
          cid: 500104,
          name: '大渡口',
        },
        {
          cid: 500105,
          name: '江北',
        },
        {
          cid: 500106,
          name: '沙坪坝',
        },
        {
          cid: 500107,
          name: '九龙坡',
        },
        {
          cid: 500108,
          name: '南岸',
        },
        {
          cid: 500109,
          name: '北碚',
        },
        {
          cid: 500110,
          name: '綦江',
        },
        {
          cid: 500111,
          name: '大足区',
        },
        {
          cid: 500112,
          name: '渝北',
        },
        {
          cid: 500113,
          name: '巴南',
        },
        {
          cid: 500114,
          name: '黔江',
        },
        {
          cid: 500115,
          name: '长寿',
        },
        {
          cid: 500116,
          name: '江津',
        },
        {
          cid: 500117,
          name: '合川',
        },
        {
          cid: 500118,
          name: '永川',
        },
        {
          cid: 500119,
          name: '南川',
        },
        {
          cid: 500120,
          name: '璧山',
        },
        {
          cid: 500151,
          name: '铜梁',
        },
        {
          cid: 500223,
          name: '潼南',
        },
        {
          cid: 500226,
          name: '荣昌',
        },
        {
          cid: 500228,
          name: '梁平',
        },
        {
          cid: 500229,
          name: '城口',
        },
        {
          cid: 500230,
          name: '丰都',
        },
        {
          cid: 500231,
          name: '垫江',
        },
        {
          cid: 500232,
          name: '武隆',
        },
        {
          cid: 500233,
          name: '忠县',
        },
        {
          cid: 500234,
          name: '开州区',
        },
        {
          cid: 500235,
          name: '云阳',
        },
        {
          cid: 500236,
          name: '奉节',
        },
        {
          cid: 500237,
          name: '巫山',
        },
        {
          cid: 500238,
          name: '巫溪',
        },
      ],
      cid: 500000,
      name: '重庆',
    },
    {
      children: [
        {
          cid: 410500,
          name: '安阳',
        },
        {
          cid: 410600,
          name: '鹤壁',
        },
        {
          cid: 419001,
          name: '济源',
        },
        {
          cid: 410800,
          name: '焦作',
        },
        {
          cid: 410200,
          name: '开封',
        },
        {
          cid: 410300,
          name: '洛阳',
        },
        {
          cid: 411100,
          name: '漯河',
        },
        {
          cid: 411300,
          name: '南阳',
        },
        {
          cid: 410400,
          name: '平顶山',
        },
        {
          cid: 410900,
          name: '濮阳',
        },
        {
          cid: 411200,
          name: '三门峡',
        },
        {
          cid: 411400,
          name: '商丘',
        },
        {
          cid: 410700,
          name: '新乡',
        },
        {
          cid: 411500,
          name: '信阳',
        },
        {
          cid: 411000,
          name: '许昌',
        },
        {
          cid: 410100,
          name: '郑州',
        },
        {
          cid: 411600,
          name: '周口',
        },
        {
          cid: 411700,
          name: '驻马店',
        }
      ],
      cid: 410000,
      name: '河南',
    },
    {
      children: [
        {
          cid: 620100,
          name: '兰州',
        },
        {
          cid: 621200,
          name: '陇南',
        },
        {
          cid: 620800,
          name: '平凉',
        },
        {
          cid: 621000,
          name: '庆阳',
        },
        {
          cid: 620500,
          name: '天水',
        },
        {
          cid: 620600,
          name: '武威',
        },
        {
          cid: 620700,
          name: '张掖',
        },
        {
          cid: 620400,
          name: '白银',
        },
        {
          cid: 621100,
          name: '定西',
        },
        {
          cid: 620200,
          name: '嘉峪关',
        },
        {
          cid: 620300,
          name: '金昌',
        },
        {
          cid: 620900,
          name: '酒泉',
        }
      ],
      cid: 620000,
      name: '甘肃',
    },
    {
      children: [
        {
          cid: 430100,
          name: '长沙',
        },
        {
          cid: 430700,
          name: '常德',
        },
        {
          cid: 431100,
          name: '永州',
        },
        {
          cid: 430600,
          name: '岳阳',
        },
        {
          cid: 430800,
          name: '张家界',
        },
        {
          cid: 430200,
          name: '株洲',
        },
        {
          cid: 431000,
          name: '郴州',
        },
        {
          cid: 430400,
          name: '衡阳',
        },
        {
          cid: 431200,
          name: '怀化',
        },
        {
          cid: 431300,
          name: '娄底',
        },
        {
          cid: 430500,
          name: '邵阳',
        },
        {
          cid: 430300,
          name: '湘潭',
        },
        {
          cid: 430900,
          name: '益阳',
        }
      ],
      cid: 430000,
      name: '湖南',
    }
  ]
