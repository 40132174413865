<template>
  <div
    class="
      user-tag
      part
      border-radius-6
      padding-20
      border-radius-6
      box-shadow-light-grey
      height-calc-type1
    "
  >
    <div class="operation-tab">
      <el-button @click="initData" style="margin-right: 20px"
        >刷新数据</el-button
      >
      <el-select
        v-model="params.accountChannelId"
        placeholder="请选择公众号"
        @change="initData"
        style="width: 140px; margin-right: 20px"
        filterable
        clearable
      >
        <el-option
          v-for="item in channelList"
          :key="item.id"
          :label="item.channelName"
          :value="item.id"
        >
          <span style="float: left">{{ item.channelName }}</span>
          <span style="float: right; margin-left: 15px; font-size: 13px"
            >{{ item.platformStr }} - {{ item.uname }}</span
          >
        </el-option>
      </el-select>
      <el-input
        v-model="params.tagName"
        clearable
        placeholder="请输入标签名"
        style="width: 200px; margin-right: 20px"
      >
        <el-button
          slot="append"
          icon="el-icon-search"
          @click="initData"
        ></el-button>
      </el-input>
      <el-input
        v-model="params.remark"
        clearable
        placeholder="请输入备注"
        style="width: 200px; margin-right: 20px"
      >
        <el-button
          slot="append"
          @click="initData"
          icon="el-icon-search"
        ></el-button>
      </el-input>
      <el-select
        style="width: 150px; margin-right: 20px"
        @change="initData"
        clearable
        v-model="params.status"
        placeholder="状态"
      >
        <el-option :value="0" label="待上传"></el-option>
        <el-option :value="1" label="上传中"></el-option>
        <el-option :value="2" label="成功"></el-option>
        <el-option :value="3" label="失败"></el-option>
      </el-select>

      <el-button @click="addTag" type="primary" style="margin-right: 10px"
        >新增标签</el-button
      >
      <el-button
        @click="copyItems"
        type="info"
        style="margin-right: 10px"
        :disabled="selectedList.length === 0"
        >批量复制</el-button
      >
      <el-button
        @click="uploadItems"
        type="warning"
        style="margin-right: 20px"
        :disabled="selectedList.length === 0"
      >
        批量上传
      </el-button>
    </div>
    <div class="custom-table">
      <el-table
        :data="dataList"
        style="width: 100%"
        height="70vh"
        @selection-change="handleSelectionChange"
        v-loading="tableLoading"
      >
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column label="id" prop="id" width="80"></el-table-column>
        <el-table-column
          label="公众号名称"
          prop="channelName"
        ></el-table-column>
        <el-table-column label="标签名" prop="tagName"></el-table-column>
        <el-table-column label="备注" prop="remark"></el-table-column>
        <el-table-column label="上传状态">
          <template slot-scope="scope">
            <el-tag :type="getType(scope.row).type">{{
              getType(scope.row).name
            }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="失败原因">
          <template slot-scope="scope">
            <template v-if="scope.row.failReason">
              <el-popover placement="right" width="400" trigger="click">
                <div>{{ scope.row.failReason }}</div>
                <span class="line-overflow" slot="reference">{{
                  scope.row.failReason
                }}</span>
              </el-popover>
            </template>
            <template v-else>
              <div>--</div>
            </template>
          </template>
        </el-table-column>
        <el-table-column label="创建时间" prop="createdTime"></el-table-column>
        <el-table-column label="操作" width="400" fixed="right">
          <template slot-scope="scope">
            <el-button
              type="success"
              size="small"
              plain
              class="button-small"
              style="margin-right: 10px"
              @click="checkDetail(scope.row)"
            >
              详情
            </el-button>
            <el-button
              type="primary"
              size="small"
              class="button-small"
              style="margin-right: 10px"
              @click="editItem(scope.row)"
              >编辑</el-button
            >
            <el-button
              type="warning"
              size="small"
              class="button-small"
              style="margin-right: 10px"
              @click="uploadItem(scope.row)"
              >上传</el-button
            >
            <el-button
              size="small"
              class="button-small"
              @click="addTagging(scope.row)"
              style="margin-right: 10px"
            >
              打标签
            </el-button>
            <el-button
              type="danger"
              size="small"
              class="button-small"
              @click="delItem(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        style="margin: 20px 0; text-align: right"
        background
        @current-change="handlePageChange"
        :current-page="page"
        :page-size="pageSize"
        layout="total, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <add-tag
      :show="isShowDrawer"
      :curInfo="curInfo"
      :isEdit="isEdit"
      :channelList="channelList"
      :submitLoading="submitLoading"
      @closeDrawer="closeDrawer"
      @handleSubmit="handleSubmit"
    >
    </add-tag>
    <el-dialog
      title="复制"
      width="500px"
      v-dialogDrag
      :visible.sync="isShowDialog"
    >
      <el-select
        v-model="accountChannelIds"
        placeholder="请选择公众号"
        filterable
        clearable
        multiple
        style="width: 100%"
      >
        <el-option
          v-for="item in channelList"
          :key="item.id"
          :label="item.channelName"
          :value="item.id"
        >
          <span style="float: left">{{ item.channelName }}</span>
          <span style="float: right; margin-left: 15px; font-size: 13px"
            >{{ item.platformStr }} - {{ item.uname }}</span
          >
        </el-option>
      </el-select>
      <div style="text-align: center; margin-top: 20px">
        <el-button type="primary" @click="ok">确定</el-button>
        <el-button @click="cancel">取消</el-button>
      </div>
    </el-dialog>
    <tag-detail
      :detailInfo="detailInfo"
      :show="showTagDetail"
      @close="closeDetail"
    ></tag-detail>
  </div>
</template>

<script>
import {
  copyTag,
  addUserTag,
  delUserTag,
  editUserTag,
  batchUpload,
  getUserTagList,
  getUserTagDetail,
  addUserNewTag,
} from "@/api/user.js";
import { mapGetters, mapActions } from "vuex";
import { messagePlatformList } from "@/assets/js/options"; // 客服消息支持平台
import addTag from "./add-tag";
import sendDetailTable from "@/views/service/components/sendDetailTable.vue";
import tagDetail from "./detail";
import moment from "moment";

export default {
  data() {
    return {
      id: null,
      page: 1,
      ids: null,
      total: 0,
      params: {},
      curInfo: {
        tagType: 0,
        sendAll: 0,
        isVip: 2,
        rechargeType: 2,
        continuousRecharge: 0,
        couponLevel: 0,
        rfm: 0,
        sex: 0,
      },
      pageSize: 15,
      dataList: [],
      isEdit: false,
      loading: false,
      showDetail: false,
      detailInfo: {},
      selectedList: [],
      messagePlatformList,
      isShowDialog: false,
      isShowDrawer: false,
      tableLoading: false,
      showTagDetail: false,
      submitLoading: false,
      accountChannelIds: [],
      currentOfficial: null,
    };
  },
  mounted() {
    this.initData();
  },
  computed: {
    ...mapGetters(["officialList"]),
    channelList() {
      const { officialList } = this;
      return officialList.filter((item) => item.status === 1);
    },
  },
  methods: {
    initData() {
      this.tableLoading = true;
      getUserTagList({
        ...this.params,
        page: this.page,
        pageSize: this.pageSize,
      })
        .then((res) => {
          this.dataList = res.list;
          this.total = res.total;
        })
        .finally(() => {
          this.tableLoading = false;
        });
    },
    handlePageChange(page) {
      this.page = page;
      this.initData();
    },
    editItem(row) {
      this.isEdit = true;
      this.isShowDrawer = true;
      const { id } = row;
      this.id = id;
      getUserTagDetail(this.id).then((res) => {
        this.curInfo = {
          ...res,
          rfm:
            res.rfm === "0"
              ? []
              : res.rfm.split(",").map((item) => Number(item)),
        };
      });
    },
    delItem(row) {
      const { id } = row;
      this.$confirm("删除的数据将无法恢复!", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        delUserTag(id).then(() => {
          this.$message.success("删除成功!");
          this.initData();
        });
      });
    },
    uploadItems() {
      this.$confirm("确定上传吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        const ids = this.selectedList.map((item) => item.id).join(",");
        batchUpload(ids).then(() => {
          this.$message.success("操作成功!");
          this.initData();
          this.selectedList = [];
        });
      });
    },
    uploadItem(row) {
      this.$confirm("确定上传吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          batchUpload(row.id).then(() => {
            this.$message.success("操作成功!");
            this.initData();
            this.selectedList = [];
          });
        })
        .catch(() => {});
    },
    handleOfficialChange(page) {
      this.page = page;
      this.initData();
    },
    addTag() {
      this.isEdit = false;
      this.isShowDrawer = true;
      this.curInfo = {
        tagType: 0,
        sendAll: 0,
        isVip: 2,
        rechargeType: 2,
        continuousRecharge: 0,
        couponLevel: 0,
        rfm: [],
        sex: 0,
      };
    },
    getType(row) {
      if (row.status === 0) {
        return {
          type: "warning",
          name: "待上传",
        };
      }
      if (row.status === 1) {
        return {
          type: "info",
          name: "上传中",
        };
      }
      if (row.status === 2) {
        return {
          type: "success",
          name: "上传成功",
        };
      }
      if (row.status === 3) {
        return {
          type: "danger",
          name: "上传失败",
        };
      }
    },
    isNull(value) {
      return value === undefined || value === null ? null : value;
    },
    handleSelectionChange(select) {
      this.selectedList = select;
    },
    closeDetail() {
      this.showTagDetail = false;
      this.detailInfo = {};
    },
    checkDetail(row) {
      const { id } = row;
      getUserTagDetail(id).then((res) => {
        this.detailInfo = res;
        this.showTagDetail = true;
      });
    },
    handleSubmit(val) {
      this.submitLoading = true;
      const {
        tagType,
        isVip,
        continuousRecharge,
        couponLevel,
        rfm,
        bookId,
        sex,
        sendAll,
        startDate,
        endDate,
      } = val;

      const defaultValue = {
        tagType: this.isNull(tagType),
        isVip: this.isNull(isVip),
        continuousRecharge: this.isNull(continuousRecharge),
        couponLevel: this.isNull(couponLevel),
        rfm: this.isNull(rfm),
        bookId: this.isNull(bookId),
        sex: this.isNull(sex),
        sendAll: this.isNull(sendAll),
        startDate: this.isNull(startDate),
        endDate: this.isNull(endDate),
      };
      if (!this.isEdit) {
        addUserTag({
          ...val,
          ...defaultValue,
          startDate: val.startDate
            ? moment(val.startDate).format("YYYY-MM-DD HH:mm:ss")
            : null,
          endDate: val.endDate
            ? moment(val.endDate).format("YYYY-MM-DD HH:mm:ss")
            : null,
        })
          .then(() => {
            this.$message.success("添加标签成功！");
            this.isShowDrawer = false;
            this.initData();
            this.resetData();
          })
          .finally(() => {
            this.submitLoading = false;
          });
      } else {
        editUserTag(this.id, {
          ...val,
          ...defaultValue,
          startDate: val.startDate
            ? moment(val.startDate).format("YYYY-MM-DD HH:mm:ss")
            : null,
          endDate: val.endDate
            ? moment(val.endDate).format("YYYY-MM-DD HH:mm:ss")
            : null,
        })
          .then(() => {
            this.$message.success("修改成功!");
            this.isShowDrawer = false;
            this.initData();
            this.resetData();
          })
          .finally(() => {
            this.submitLoading = false;
          });
      }
    },
    copyItems() {
      this.ids = this.selectedList.map((item) => item.id).join(",");
      this.isShowDialog = true;
    },
    copyItem(row) {
      const { id } = row;
      this.ids = id;
      this.isShowDialog = true;
    },
    ok() {
      if (
        !Array.isArray(this.accountChannelIds) &&
        this.accountChannelIds.length === 0
      ) {
        return this.$message.error("请选择公众号!");
      }
      copyTag(this.ids, {
        accountChannelIds: this.accountChannelIds,
      }).then(() => {
        this.$message.success("复制成功!");
        this.isShowDialog = false;
        this.accountChannelIds = [];
        this.selectedList = [];
        this.initData();
      });
    },
    cancel() {
      this.isShowDialog = false;
      this.accountChannelIds = [];
    },
    // 用户打标签
    addTagging(row) {
      this.$confirm("确定打标签吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          addUserNewTag(row.id).then(() => {
            this.$message.success("操作成功！");
            this.initData();
          });
        })
        .catch(() => {});
    },
    closeDrawer() {
      this.isShowDrawer = false;
    },
    resetData() {
      this.isEdit = false;
      this.id = null;
      this.curInfo = {
        tagType: 0,
        sendAll: 0,
        isVip: 2,
        rechargeType: 2,
        continuousRecharge: 0,
        couponLevel: 0,
        rfm: 0,
        sex: 0,
      };
    },
  },
  components: {
    addTag,
    tagDetail,
    sendDetailTable,
  },
};
</script>
<style
    SendDetailTable lang='scss' scoped>
.user-tag {
  background: #fff;
}
.custom-table {
  margin-top: 20px;
}
</style>
