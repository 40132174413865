<template>
  <el-dialog
    :title="`${isEdit ? '编辑' : '新增'}标签`"
    :visible.sync="show"
    :before-close="handleClose"
    width="100%"
    fullscreen
    append-to-body
  >
    <div class="add-tag-container">
      <el-form
        ref="form"
        label-width="150px"
        class="border-radius-6 custom-form"
        :model="curInfo"
        :rules="formRule"
      >
        <div class="form-container">
          <div class="form-left">
            <el-form-item prop="accountChannelId" label="公众号">
              <el-select
                v-model="curInfo.accountChannelId"
                placeholder="请选择公众号"
                style="width: 100%"
                :disabled="isEdit"
                clearable
                filterable
                @change="handleAccountChange"
              >
                <el-option
                  v-for="item in channelList"
                  :key="item.id"
                  :label="item.channelName"
                  :value="item.id"
                >
                  <span style="float: left">{{ item.channelName }}</span>
                  <span style="float: right; margin-left: 15px; font-size: 13px"
                    >{{ item.platformStr }} - {{ item.uname }}</span
                  >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="tagName" label="标签名">
              <el-input
                v-model="curInfo.tagName"
                placeholder="请输入标签名"
                maxlength="30"
                show-word-limit
              ></el-input>
            </el-form-item>
            <el-form-item prop="remark" label="备注">
              <el-input
                placeholder="给这条消息起个名字便于查询!"
                v-model="curInfo.remark"
              ></el-input>
            </el-form-item>
            <el-form-item label="发送次数排除">
              <el-input-number
                v-model="curInfo.minSendNum"
                placeholder="最小数量"
                :min="0"
                style="width: 150px; margin-right: 10px"
              ></el-input-number>
              <el-input-number
                v-model="curInfo.maxSendNum"
                :min="0"
                placeholder="最大数量"
                style="width: 150px; margin-right: 10px"
              ></el-input-number>
            </el-form-item>
            <el-form-item prop="tagType" label="互动类型">
              <el-radio-group v-model="curInfo.tagType">
                <el-radio :label="0">全部</el-radio>
                <el-radio :label="1">活跃</el-radio>
                <el-radio :label="2">不活跃</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="接收用户">
              <el-radio-group v-model="curInfo.sendAll">
                <el-radio-button :label="1">全部用户</el-radio-button>
                <el-radio-button :label="0">标签用户</el-radio-button>
              </el-radio-group>
            </el-form-item>
          </div>
          <div class="form-right">
            <div class="prohibit">
              <el-form-item prop="rechargeType" label="充值情况">
                <el-radio-group v-model="curInfo.rechargeType">
                  <el-radio-button :label="0">不限</el-radio-button>
                  <el-radio-button :label="1">未充值</el-radio-button>
                  <el-radio-button :label="2">已充值</el-radio-button>
                </el-radio-group>
              </el-form-item>

              <template v-if="curInfo.rechargeType === 2">
                <div class="custom-part">
                  <div>
                    <el-form-item label="充值间隔">
                      <el-input-number
                        :min="0"
                        v-model="curInfo.rechargeTimeSpaceStart"
                        placeholder="间隔开始"
                        style="width: 150px; margin-right: 10px"
                      ></el-input-number>
                      <el-input-number
                        :min="0"
                        v-model="curInfo.rechargeTimeSpaceEnd"
                        placeholder="间隔结束"
                        style="width: 150px"
                      ></el-input-number>
                    </el-form-item>
                    <el-form-item label="累计充值">
                      <el-input-number
                        :min="0"
                        v-model="curInfo.minTotalRecharge"
                        placeholder="最小充值"
                        style="width: 150px; margin-right: 10px"
                      ></el-input-number>
                      <el-input-number
                        :min="0"
                        v-model="curInfo.maxTotalRecharge"
                        placeholder="最大充值"
                        style="width: 150px; margin-right: 10px"
                      ></el-input-number>
                    </el-form-item>
                    <el-form-item
                      label="最后充值"
                      v-if="curInfo.rechargeType === 2"
                    >
                      <el-input-number
                        style="width: 150px"
                        v-model="curInfo.minLastRechargeAmt"
                        placeholder="最小充值"
                        :precision="2"
                        :step="1"
                        :min="0"
                      ></el-input-number>
                      <span style="margin: 0 5px"></span>
                      <el-input-number
                        style="width: 150px"
                        placeholder="最大充值"
                        v-model="curInfo.maxLastRechargeAmt"
                        :precision="2"
                        :step="1"
                        :min="0"
                      ></el-input-number>
                    </el-form-item>
                    <el-form-item label="充值次数">
                      <el-input-number
                        :min="0"
                        v-model="curInfo.minRechargeCount"
                        placeholder="最小次数"
                        style="width: 150px; margin-right: 10px"
                      ></el-input-number>
                      <el-input-number
                        :min="0"
                        v-model="curInfo.maxRechargeCount"
                        placeholder="最大次数"
                        style="width: 150px; margin-right: 10px"
                      ></el-input-number>
                    </el-form-item>
                    <el-form-item label="近七日充值">
                      <el-input-number
                        :min="0"
                        v-model="curInfo.minSevenRecharge"
                        placeholder="最小充值"
                        style="width: 150px; margin-right: 10px"
                      ></el-input-number>
                      <el-input-number
                        :min="0"
                        v-model="curInfo.maxSevenRecharge"
                        placeholder="最大充值"
                        style="width: 150px; margin-right: 10px"
                      ></el-input-number>
                    </el-form-item>
                    <el-form-item
                      label="书币消耗率"
                      v-if="[0, 3].includes(platformId)"
                    >
                      <el-input-number
                        :min="0"
                        placeholder="最小消耗率"
                        v-model="curInfo.minKanDianRate"
                        class="margin-right-ten"
                        style="width: 150px"
                      ></el-input-number>
                      <el-input-number
                        :min="0"
                        placeholder="最大消耗率"
                        v-model="curInfo.maxKanDianRate"
                        style="width: 150px"
                      ></el-input-number>
                    </el-form-item>
                    <el-form-item label="剩余书币">
                      <el-input-number
                        :min="0"
                        v-model="curInfo.minKanDian"
                        placeholder="最小书币"
                        style="width: 150px; margin-right: 10px"
                      ></el-input-number>
                      <el-input-number
                        :min="0"
                        v-model="curInfo.maxKanDian"
                        placeholder="最大书币"
                        style="width: 150px; margin-right: 10px"
                      ></el-input-number>
                    </el-form-item>
                    <el-form-item label="是否是VIP">
                      <el-radio-group v-model="curInfo.isVip">
                        <el-radio :label="0">否</el-radio>
                        <el-radio :label="1">是</el-radio>
                        <el-radio :label="2">不限</el-radio>
                      </el-radio-group>
                    </el-form-item>
                    <el-form-item label="连续充值">
                      <el-radio-group v-model="curInfo.continuousRecharge">
                        <el-radio :label="0">不限</el-radio>
                        <el-radio :label="1">是</el-radio>
                      </el-radio-group>
                    </el-form-item>
                    <el-form-item label="优惠敏感度">
                      <el-select
                        v-model="curInfo.couponLevel"
                        placeholder="优惠敏感度"
                        clearable
                        filterable
                      >
                        <el-option label="不限" :value="0"></el-option>
                        <el-option label="一级" :value="1"></el-option>
                        <el-option label="二级" :value="2"></el-option>
                        <el-option label="三级" :value="3"></el-option>
                        <el-option label="四级" :value="4"></el-option>
                        <el-option label="五级" :value="5"></el-option>
                      </el-select>
                    </el-form-item>
                    <div class="rfm-box">
                      <el-form-item label="RFM" class="rfm-container">
                        <div slot="label">
                          <el-popover
                            placement="right"
                            width="500"
                            trigger="hover"
                          >
                            <el-table :data="gridData" height="300">
                              <el-table-column
                                width="120"
                                property="label"
                                label="客户标签"
                              ></el-table-column>
                              <el-table-column
                                width="36"
                                property="R"
                                label="R"
                              ></el-table-column>
                              <el-table-column
                                width="36"
                                property="F"
                                label="F"
                              ></el-table-column>
                              <el-table-column
                                width="36"
                                property="M"
                                label="M"
                              ></el-table-column>
                              <el-table-column
                                property="scene"
                                label="场景"
                              ></el-table-column>
                              <el-table-column
                                width="50"
                                property="level"
                                label="等级"
                              ></el-table-column>
                            </el-table>
                            <i
                              slot="reference"
                              style="margin-left: 20px"
                              class="el-icon-question"
                            ></i>
                          </el-popover>
                          <span>RFM</span>
                        </div>
                        <el-select
                          v-model="curInfo.rfm"
                          value-key=""
                          placeholder="请选择客户类型"
                          clearable
                          filterable
                          multiple
                        >
                          <el-option
                            v-for="item in options"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                          >
                          </el-option>
                        </el-select>
                      </el-form-item>
                    </div>

                    <el-form-item prop="bookId" label="书籍">
                      <el-select
                        placeholder="选择书籍"
                        v-model="curInfo.bookId"
                        reserve-keyword
                        clearable
                        filterable
                        remote
                        :remote-method="getBooks"
                        :loading="bookLoading"
                      >
                        <el-option
                          v-if="defaultBook"
                          :label="defaultBook.bookName"
                          :value="defaultBook.id"
                        ></el-option>
                        <el-option
                          v-for="item in bookList"
                          :key="item.id"
                          :label="item.bookName"
                          :value="item.id"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                  </div>
                </div>
              </template>
              <el-form-item prop="sex" label="性别">
                <el-radio-group v-model="curInfo.sex">
                  <el-radio :label="0">不限</el-radio>
                  <el-radio :label="1">男</el-radio>
                  <el-radio :label="2">女</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item prop="province" label="所在地区">
                <el-select
                  v-model="curInfo.province"
                  filterable
                  clearable
                  style="width: 150px"
                  class="margin-right-ten"
                  @change="handleAreaChange"
                  @clear="clearArea"
                >
                  <el-option
                    v-for="item in area"
                    :label="item.name"
                    :value="item.name"
                    :key="item.cid"
                  >
                  </el-option>
                </el-select>
                <el-select
                  v-model="curInfo.city"
                  filterable
                  clearable
                  style="width: 150px"
                >
                  <el-option
                    v-for="item in citys"
                    :label="item.name"
                    :value="item.name"
                    :key="item.cid"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="签到次数">
                <el-input-number
                  :min="0"
                  placeholder="最小签到次数"
                  v-model="curInfo.minSignInCount"
                  class="margin-right-ten"
                  style="width: 150px"
                ></el-input-number>
                <el-input-number
                  :min="0"
                  placeholder="最大签到次数"
                  v-model="curInfo.maxSignInCount"
                  style="width: 150px"
                ></el-input-number>
              </el-form-item>
              <el-form-item label="关注时间">
                <el-date-picker
                  v-model="curInfo.startDate"
                  type="datetime"
                  class="margin-right-ten"
                  style="width: 200px"
                  placeholder="开始日期"
                >
                </el-date-picker>
                <el-date-picker
                  v-model="curInfo.endDate"
                  type="datetime"
                  style="width: 200px"
                  placeholder="结束日期"
                >
                </el-date-picker>
              </el-form-item>
              <div v-if="curInfo.sendAll === 1" class="prohibit-text">
                <span>仅限标签用户操作!</span>
              </div>
            </div>
          </div>
        </div>
      </el-form>
    </div>
    <div class="button-list">
      <el-button
        type="primary"
        @click="handleSubmit('form')"
        :loading="submitLoading"
        >确定</el-button
      >
      <el-button @click="handleCancel">取消</el-button>
      <el-button
        :disabled="!curInfo.accountChannelId"
        type="text"
        @click="checkTagNum"
        >点击查看标签人数</el-button
      >
      <span
        style="text-decoration: underline; cursor: pointer"
        @click="showDetail = true"
        v-if="showTagNum"
        >预计人数：{{ number }}人</span
      >
    </div>
    <send-detail-table
      :params="getNumParams"
      @closeDetail="closeDetail"
      :show="showDetail"
      type="tag"
    >
    </send-detail-table>
  </el-dialog>
</template>

<script>
import area from "@/assets/js/area.js";
import { getBookList } from "@/api/account";
import { getSendTagNum } from "@/api/service";
import SendDetailTable from "@/views/service/components/sendDetailTable.vue";
import { platformList } from "../../assets/js/options";
import moment from "moment";

export default {
  name: "AddTag",
  props: {
    show: Boolean,
    isEdit: {
      type: Boolean,
      default: false,
    },
    curInfo: {
      type: Object,
      default: () => {},
    },
    channelList: {
      type: Array,
      default: () => [],
    },
    submitLoading: Boolean,
  },
  data() {
    return {
      area,
      citys: [],
      bookLoading: false,
      bookList: [],
      number: null,
      getNumParams: {},
      showDetail: false,
      defaultBook: null,
      showTagNum: false,
      platformId: null,
      options: [
        { label: "A类", value: 1 },
        { label: "B类", value: 2 },
        { label: "C类", value: 3 },
      ],
      formRule: {
        accountChannelId: [
          {
            required: true,
            message: "请选择公众号!",
            trigger: "blur",
          },
        ],
        tagName: [
          {
            required: true,
            message: "请输入标签名!",
            trigger: "blur",
          },
        ],
      },
      gridData: [
        {
          label: "重要价值客户",
          R: "高",
          F: "高",
          M: "高",
          scene: "最近充值-高频次-高金额",
          level: "A类",
        },
        {
          label: "重要深耕客户",
          R: "高",
          F: "低",
          M: "高",
          scene: "最近充值-低频次-高金额",
          level: "A类",
        },
        {
          label: "重要唤回客户",
          R: "低",
          F: "高",
          M: "高",
          scene: "最近未充-高频次-高金额",
          level: "B类",
        },
        {
          label: "重要挽留客户",
          R: "低",
          F: "低",
          M: "高",
          scene: "最近未充-低频次-高金额",
          level: "B类",
        },
        {
          label: "潜力客户",
          R: "高",
          F: "高",
          M: "低",
          scene: "最近充值-高频次-低金额",
          level: "B类",
        },
        {
          label: "一般发展客户",
          R: "高",
          F: "低",
          M: "低",
          scene: "最近充值-低频次-低金额",
          level: "B类",
        },
        {
          label: "一般维持客户",
          R: "低",
          F: "高",
          M: "低",
          scene: "最近未充-高频次-低金额",
          level: "B类",
        },
        {
          label: "流失客户",
          R: "低",
          F: "低",
          M: "低",
          scene: "最近未充-低频次-低金额",
          level: "C类",
        },
      ],
    };
  },
  watch: {
    show: {
      handler(newV) {
        if (newV) {
          this.initData();
        } else {
          this.showTagNum = false;
          this.number = null;
        }
      },
    },
    "curInfo.province": {
      handler(newV) {
        if (newV) {
          this.handleAreaChange(newV);
        }
      },
      immediate: true,
    },
  },
  methods: {
    initData() {
      getBookList({ page: 1, pageSize: 50 }).then((res) => {
        this.bookList = res.list;
      });
    },
    handleClose() {
      this.$emit("closeDrawer");
    },
    handleAreaChange(val) {
      if (val) {
        this.citys = this.area.find((item) => item.name === val).children;
      }
    },
    handleAccountChange(val) {
      this.platformId = this.channelList.find(
        (item) => item.id === val
      ).platform;
    },

    clearArea() {
      this.curInfo.city = null;
    },
    getBooks(query) {
      this.defaultBook = null;
      getBookList({ page: 1, pageSize: 50, bookName: query }).then((res) => {
        this.bookList = res.list;
      });
    },
    handleSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.curInfo.rfm =
            Array.isArray(this.curInfo.rfm) && this.curInfo.rfm.length > 0
              ? this.curInfo.rfm.join(",")
              : "0";
          this.$emit("handleSubmit", this.curInfo);
        }
      });
    },
    handleCancel() {
      this.citys = [];
      this.$emit("closeDrawer");
    },
    checkTagNum() {
      const params = {
        accountChannelId: this.curInfo.accountChannelId,
        sendAll: this.curInfo.sendAll,
        tagType: this.curInfo.tagType,
        minSendNum: this.curInfo.minSendNum,
        maxSendNum: this.curInfo.maxSendNum,
      };
      if (this.curInfo.sendAll !== 1) {
        params.rechargeType = this.curInfo.rechargeType;
        params.bookId = this.curInfo.bookId;
        params.sex = this.curInfo.sex;
        (params.startDate = this.curInfo.startDate
          ? moment(this.curInfo.startDate).format("YYYY-MM-DD HH:mm:ss")
          : null),
          (params.endDate = this.curInfo.endDate
            ? moment(this.curInfo.endDate).format("YYYY-MM-DD HH:mm:ss")
            : null),
          (params.rechargeTimeSpaceStart = this.curInfo.rechargeTimeSpaceStart);
        params.rechargeTimeSpaceEnd = this.curInfo.rechargeTimeSpaceEnd;
        params.minTotalRecharge = this.curInfo.minTotalRecharge;
        params.maxTotalRecharge = this.curInfo.maxTotalRecharge;
        // 最大最小最后充值
        params.minLastRechargeAmt = this.curInfo.minLastRechargeAmt;
        params.maxLastRechargeAmt = this.curInfo.maxLastRechargeAmt;
        params.province = this.curInfo.province;
        params.city = this.curInfo.city;
        params.continuousRecharge = this.curInfo.continuousRecharge;
        params.maxSevenRecharge = this.curInfo.maxSevenRecharge;
        params.minSevenRecharge = this.curInfo.minSevenRecharge;
        params.minSendNum = this.curInfo.minSendNum;
        params.maxSendNum = this.curInfo.maxSendNum;
        params.isVip = this.curInfo.isVip;
        // params.rfm = this.curInfo.rfm;
        params.rfm =
          Array.from(this.curInfo.rfm) && this.curInfo.rfm.length === 0
            ? "0"
            : this.curInfo.rfm.join(",");
        params.couponLevel = this.curInfo.couponLevel;
      }
      let plaform = this.channelList.find(
        (item) => item.id === this.curInfo.accountChannelId
      ).plaform;
      if (this.curInfo.rechargeType === 2 && (plaform === 3 || plaform === 0)) {
        params.minKanDian = this.curInfo.minKanDian;
        params.maxKanDian = this.curInfo.maxKanDian;
      }
      this.getNumParams = params;

      getSendTagNum(params).then((res) => {
        this.showTagNum = true;
        this.number = res;
      });
    },
    closeDetail() {
      this.showDetail = false;
    },
  },
  components: {
    SendDetailTable,
  },
};
</script>
<style lang='scss' scoped>
.add-tag-container {
  position: relative;
  .custom-form {
    width: 1300px;
    margin: 0 auto;
    margin-top: 10px;
    padding: 10px;
    background: #fbfbfb;
  }
}
/deep/ .el-dialog {
  height: 100vh;
}
/deep/ .el-dialog__body {
  padding-top: 0px !important;
  overflow: hidden;
  background: #fff;
}
/deep/ .el-form-item {
  margin-bottom: 10px;
}
/deep/ .el-dialog__header {
  padding: 15px;
  text-align: center;
}
/deep/ .el-form-item__label {
  text-align: left;
}
/deep/ .el-popover__reference-wrapper {
  i {
    margin-left: 0px !important;
    margin-right: 5px;
  }
}
.form-container {
  margin: 0 auto;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  .form-left {
    width: 40%;
  }
  .form-right {
    flex-grow: 1;
    margin-left: 50px;
  }
}
.button-list {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  padding-bottom: 20px;
  text-align: center;
  border-top: 1px dashed #f2f2f2;
  background-color: #fff;
  padding-top: 10px;
}
.custom-part {
  display: inline-block;
  padding: 15px 12px 0 12px;
  box-sizing: content-box;
  border-radius: 20px;
  position: relative;
  background-color: #f6f6f6;
  left: -10px;
  > div {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
  }
}
.rfm-box {
  margin .rfm-container {
    display: inline-block !important;
  }
}
.prohibit {
  position: relative;
  .prohibit-text {
    position: absolute;
    top: 0;
    left: 0;
    color: #fff;
    bottom: 0;
    right: 0;
    background-color: rgba(171, 171, 171, 0.7);
    z-index: 999;
    cursor: not-allowed;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    > span {
      font-size: 20px;
      font-weight: bold;
    }
  }
}
</style>