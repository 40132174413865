<template>
  <div>
    <el-drawer
      title="可送达人详情"
      :visible.sync="show"
      size="90%"
      :append-to-body="true"
      :before-close="handleClose"
    >
      <el-table
        :data="tableData"
        height="750px"
        v-loading="loading"
        @sort-change="handleSortChange"
      >
        <el-table-column prop="nickname" label="昵称"> </el-table-column>
        <el-table-column
          width="100px"
          sortable="custom"
          prop="isVip"
          label="vip"
        >
          <template slot-scope="scope">
            {{ scope.row.isVip ? "是" : "否" }}
          </template>
        </el-table-column>
        <el-table-column sortable="custom" prop="rmf" label="rmf">
          <template slot-scope="scope">
            {{ handleRmf(scope.row.rmf) }}
          </template>
        </el-table-column>
        <el-table-column
          width="100px"
          sortable="custom"
          prop="couponLevel"
          label="优惠力度"
        >
          <template slot-scope="scope">
            {{ scope.row.couponLevel + "级" }}
          </template>
        </el-table-column>
        <el-table-column
          width="100px"
          sortable="custom"
          prop="sendNum"
          label="发送次数"
        >
        </el-table-column>
        <el-table-column
          sortable="custom"
          prop="totalRechargeAmt"
          label="总充值"
        >
        </el-table-column>
        <el-table-column
          sortable="custom"
          prop="lastRechargeAmt"
          label="最后充值金额"
          width="130"
        >
        </el-table-column>
        <el-table-column
          sortable="custom"
          prop="todayRechargeAmt"
          label="今日充值"
        >
        </el-table-column>
        <el-table-column
          sortable="custom"
          prop="totalRechargeNum"
          label="总充值次数"
        >
        </el-table-column>
        <el-table-column
          sortable="custom"
          prop="rechargeSpace"
          label="时间间隔"
        >
          <template slot-scope="scope">
            {{
              scope.row.rechargeSpace === -1
                ? "未充值"
                : scope.row.rechargeSpace + "天"
            }}
          </template>
        </el-table-column>
        <el-table-column
          width="140px"
          prop="lastRechargeDate"
          label="最后充值时间"
        >
        </el-table-column>
        <el-table-column prop="openid" label="openid"> </el-table-column>
        <el-table-column prop="province" label="省份"> </el-table-column>
        <el-table-column prop="city" label="城市"> </el-table-column>
      </el-table>
      <!-- 普通分页 -->
      <el-pagination
        style="margin: 10px 0; text-align: right"
        background
        @current-change="handlePageChange"
        :current-page="page"
        :page-size="pageSize"
        layout="total, prev, pager, next, jumper"
        :total="totalPage"
      >
      </el-pagination>
    </el-drawer>
  </div>
</template>

<script>
import { getPlatformSendDetail, getPlatformSendDetailTag } from "@/api/service";
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    params: {
      type: Object,
      default: new Object(),
    },
    type: {
      type: String,
      default: "",
    },
  },
  computed: {
    handleRmf: function () {
      return function (value) {
        switch (value) {
          case 221:
            return "潜力客户";
          case 111:
            return "流失客户";
          case 121:
            return "一般维持客户";
          case 211:
            return "一般发展客户";
          case 122:
            return "重点唤回客户";
          case 212:
            return "重点深耕客户";
          case 112:
            return "重要挽留客户";
          case 222:
            return "重要价值客户";
          default:
            return "无";
        }
      };
    },
  },
  watch: {
    show: function (newValue) {
      if (newValue) {
        this.handleGetPlatformSendDetail();
      }
    },
  },
  data() {
    return {
      totalData: [],
      tableData: [],
      loading: false,
      page: 1,
      pageSize: 15,
      totalPage: 0,
      sortName: null,
      sortDesc: null,
    };
  },
  methods: {
    handleClose() {
      this.$emit("closeDetail");
    },
    // 获取发送人明细
    handleGetPlatformSendDetail() {
      this.loading = true;
      if (this.type === "tag") {
        getPlatformSendDetailTag({
          ...this.params,
          sortName: this.sortName,
          sortDesc: this.sortDesc,
        })
          .then((res) => {
            this.totalData = res;
            this.totalPage = this.totalData.length;
            this.handlePageChange(1);
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        getPlatformSendDetail({
          ...this.params,
          sortName: this.sortName,
          sortDesc: this.sortDesc,
        })
          .then((res) => {
            this.totalData = res;
            this.totalPage = this.totalData.length;
            this.handlePageChange(1);
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    // 静态分页
    handlePageChange(page) {
      this.page = page;
      this.tableData = this.totalData.slice(
        (this.page - 1) * this.pageSize,
        this.page * this.pageSize
      );
    },
    // 排序
    handleSortChange({ column, prop, order }) {
      this.sortName = prop;
      if (order === "descending") {
        this.sortName = prop;
        this.sortDesc = true;
      } else if (order === "ascending") {
        this.sortName = prop;
        this.sortDesc = false;
      } else {
        this.sortName = null;
        this.sortDesc = null;
      }
      this.handleGetPlatformSendDetail();
    },
  },
};
</script>

<style>
</style>