var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-dialog',{directives:[{name:"dialogDrag",rawName:"v-dialogDrag"}],attrs:{"title":"标签详情","visible":_vm.show,"before-close":_vm.handleClose},on:{"update:visible":function($event){_vm.show=$event}}},[_c('div',{staticClass:"detail-container"},[_c('div',[_c('p',[_c('span',[_vm._v("公众号：")]),_c('span',[_vm._v(_vm._s(_vm.detailInfo.channelName))])]),_c('p',[_c('span',[_vm._v("标签名：")]),_c('span',[_vm._v(_vm._s(_vm.detailInfo.tagName))])]),_c('p',[_c('span',[_vm._v("备注：")]),_c('span',[_vm._v(_vm._s(_vm.detailInfo.remark))])]),_c('p',[_c('span',[_vm._v("发送次数：")]),_c('span',[_vm._v(_vm._s(((_vm.getValue(_vm.detailInfo.minSendNum)) + " - " + (_vm.getValue( _vm.detailInfo.maxSendNum )))))])]),_c('p',[_c('span',[_vm._v("互动类型：")]),_c('span',[_c('el-tag',{attrs:{"type":_vm.getTagType(_vm.detailInfo.tagType).type}},[_vm._v(" "+_vm._s(_vm.getTagType(_vm.detailInfo.tagType).text)+" ")])],1)]),_c('p',[_c('span',[_vm._v("接收用户：")]),_c('span',[_vm._v(_vm._s(("" + (_vm.detailInfo.sendAll === 1 ? "全部用户" : "标签用户"))))])])]),(_vm.detailInfo.sendAll === 0)?_c('div',{staticClass:"recharge-detail"},[_c('p',[_c('span',[_vm._v("充值情况：")]),_c('span',[_vm._v(_vm._s(_vm.getRechargeType(_vm.detailInfo.rechargeType)))])]),(_vm.detailInfo.rechargeType === 2)?[(
            !_vm.isFalsey(_vm.detailInfo.rechargeTimeSpaceStart) ||
            !_vm.isFalsey(_vm.detailInfo.rechargeTimeSpaceEnd)
          )?_c('p',[_c('span',[_vm._v("充值间隔：")]),_c('span',[_vm._v(" "+_vm._s(((_vm.getValue(_vm.detailInfo.rechargeTimeSpaceStart)) + " - " + (_vm.getValue( _vm.detailInfo.rechargeTimeSpaceEnd ))))+" ")])]):_vm._e(),(
            !_vm.isFalsey(_vm.detailInfo.minTotalRecharge) ||
            !_vm.isFalsey(_vm.detailInfo.maxTotalRecharge)
          )?_c('p',[_c('span',[_vm._v("累计充值：")]),_c('span',[_vm._v(" "+_vm._s(((_vm.getValue(_vm.detailInfo.minTotalRecharge)) + " - " + (_vm.getValue( _vm.detailInfo.maxTotalRecharge ))))+" ")])]):_vm._e(),(
            !_vm.isFalsey(_vm.detailInfo.minLastRechargeAmt) ||
            !_vm.isFalsey(_vm.detailInfo.maxLastRechargeAmt)
          )?_c('p',[_c('span',[_vm._v("最后充值：")]),_c('span',[_vm._v(" "+_vm._s(((_vm.getValue(_vm.detailInfo.minLastRechargeAmt)) + " - " + (_vm.getValue( _vm.detailInfo.maxLastRechargeAmt ))))+" ")])]):_vm._e(),(
            !_vm.isFalsey(_vm.detailInfo.minRechargeCount) ||
            !_vm.isFalsey(_vm.detailInfo.maxRechargeCount)
          )?_c('p',[_c('span',[_vm._v("充值次数：")]),_c('span',[_vm._v(" "+_vm._s(((_vm.getValue(_vm.detailInfo.minRechargeCount)) + " - " + (_vm.getValue( _vm.detailInfo.maxRechargeCount ))))+" ")])]):_vm._e(),(
            !_vm.isFalsey(_vm.detailInfo.minSevenRecharge) ||
            !_vm.isFalsey(_vm.detailInfo.maxSevenRecharge)
          )?_c('p',[_c('span',[_vm._v("近七日充值：")]),_c('span',[_vm._v(_vm._s(((_vm.getValue(_vm.detailInfo.minSevenRecharge)) + " - " + (_vm.getValue( _vm.detailInfo.maxSevenRecharge )))))])]):_vm._e(),(
            !_vm.isFalsey(_vm.detailInfo.minKanDianRate) ||
            !_vm.isFalsey(_vm.detailInfo.maxKanDianRate)
          )?_c('p',[_c('span',[_vm._v("书币消耗率：")]),_c('span',[_vm._v(_vm._s(((_vm.getValue(_vm.detailInfo.minKanDianRate)) + " - " + (_vm.getValue( _vm.detailInfo.maxKanDianRate )))))])]):_vm._e(),(
            !_vm.isFalsey(_vm.detailInfo.minKanDian) ||
            !_vm.isFalsey(_vm.detailInfo.maxKanDian)
          )?_c('p',[_c('span',[_vm._v("剩余书币：")]),_c('span',[_vm._v(" "+_vm._s(((_vm.getValue(_vm.detailInfo.minKanDian)) + " - " + (_vm.getValue( _vm.detailInfo.maxKanDian ))))+" ")])]):_vm._e(),_c('p',[_c('span',[_vm._v("是否是vip：")]),_c('span',[_vm._v(" "+_vm._s(_vm.getVipName(_vm.detailInfo.isVip))+" ")])]),_c('p',[_c('span',[_vm._v("连续充值：")]),_c('span',[_vm._v(_vm._s(("" + (_vm.isFalsey(_vm.detailInfo.continuousRecharge) ? "暂无" : _vm.detailInfo.continuousRecharge === 0 ? "不限" : "是"))))])]),_c('p',[_c('span',[_vm._v("优惠敏感度：")]),_c('span',[_vm._v(_vm._s(("" + (_vm.getCouponLevelName(_vm.detailInfo.couponLevel)))))])]),_c('p',[_c('span',[_vm._v("RFM：")]),(_vm.detailInfo.rfm.includes('0'))?_c('el-tag',{attrs:{"type":"info"}},[_vm._v("不限")]):_vm._e(),(_vm.detailInfo.rfm.includes('1'))?_c('el-tag',{staticClass:"margin-right-ten",attrs:{"type":"success"}},[_vm._v("A类")]):_vm._e(),(_vm.detailInfo.rfm.includes('2'))?_c('el-tag',{staticClass:"margin-right-ten",attrs:{"type":"warning"}},[_vm._v("B类")]):_vm._e(),(_vm.detailInfo.rfm.includes('3'))?_c('el-tag',{staticClass:"margin-right-ten",attrs:{"type":"danger"}},[_vm._v("C类")]):_vm._e()],1),_c('p',[_c('span',[_vm._v("书籍：")]),_c('span',[_vm._v(_vm._s(_vm.detailInfo.bookName ? _vm.detailInfo.bookName : "暂无"))])])]:_vm._e(),_c('p',[_c('span',[_vm._v("性别：")]),_c('span',[_vm._v(_vm._s(_vm.getSexType(_vm.detailInfo.sex)))])]),_c('p',[_c('span',[_vm._v("所在地区：")]),_c('span',[_vm._v(_vm._s(((_vm.isFalsey(_vm.detailInfo.province) ? "" : _vm.detailInfo.province) + " - " + (_vm.isFalsey(_vm.detailInfo.city) ? "" : _vm.detailInfo.city))))])]),(
          !_vm.isFalsey(_vm.detailInfo.minSignInCount) ||
          !_vm.isFalsey(_vm.detailInfo.maxSignInCount)
        )?_c('p',[_c('span',[_vm._v("签到次数：")]),_c('span',[_vm._v(" "+_vm._s(((_vm.getValue(_vm.detailInfo.minSignInCount)) + " - " + (_vm.getValue( _vm.detailInfo.maxSignlnCount ))))+" ")])]):_vm._e(),(
          !_vm.isFalsey(_vm.detailInfo.startDate) || !_vm.isFalsey(_vm.detailInfo.endDate)
        )?_c('p',[_c('span',[_vm._v("关注时间：")]),_c('span',[_vm._v(_vm._s(((_vm.getValue(_vm.detailInfo.startDate)) + " - " + (_vm.getValue( _vm.detailInfo.endDate )))))])]):_vm._e()],2):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }